import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ViewsiteService } from '../../services/viewsite.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css'
})
export class MapComponent {
  @Input() urlname: any;
  @Input() content: any;
  @ViewChild('mapWrapper', {static: false}) mapElement: ElementRef | undefined;
  
  constructor(public viewSiteService: ViewsiteService, private router: Router) { }
  infowindow: any;
  
  ngAfterViewInit() {
    if (this.content) {
      this.initialiseSingle();
    }
    else {
      this.initialiseMulti();
    }
  }

  initialiseSingle() {
    let latlng = { lat: this.content.gmaplat, lng: this.content.gmaplong };
    const mapOptions: google.maps.MapOptions = {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      center: latlng,
      zoom: this.content.gmapzoom
    };
    let map = new google.maps.Map(this.mapElement?.nativeElement, mapOptions);
    let marker = new google.maps.Marker({
      position: latlng,
      map: map
    });
  }
  initialiseMulti() {
    this.viewSiteService.getMapMarkers(this.urlname).subscribe((markers: any) => {
      let bounds = new google.maps.LatLngBounds();
  
      const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false
      };
      
      if (markers.length == 1) {
        mapOptions.center = { lat: markers[0].gmaplat, lng: markers[0].gmaplong };
        mapOptions.zoom = markers[0].gmapzoom;
        let map = new google.maps.Map(this.mapElement?.nativeElement, mapOptions);
        let marker = new google.maps.Marker({
          position: { lat: markers[0].gmaplat, lng: markers[0].gmaplong },
          map: map
        });
      }
      else {
        let map = new google.maps.Map(this.mapElement?.nativeElement, mapOptions);
        markers.forEach((x: any) => {
          let latlng = { lat: x.gmaplat, lng: x.gmaplong };
          let marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: x.isInPast ? '#777' : '#a00',
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 15
            },
            label: {
              text: x.markerfaicon,
              fontFamily: 'FontAwesome',
              fontSize: '18px',
              color: 'white'
            },
          });

          this.infowindow = new google.maps.InfoWindow();
          marker.addListener('mouseover', () => {
            /*infowindow.open(map, marker);
            let body = 
              "<div class='infowindow-container'>" +
                "<div class='mb-3'>" + 
                  "<h4 class='me-3'>" + x.title + "</h4>" +
                  (x.displaydate ? "<div>"+x.displaydate+"</div>" : "") +
                  "<div><a href='/"+this.urlname+"/detail/"+x.contentid+"'>Vew Detail</a></div>"
                "</div>" + 
              "</div>";
            infowindow.setContent(body);*/
          });
          marker.addListener("mouseout", function() {
            //infowindow.close();
          });
          marker.addListener("click", () => {
            this.infowindow.close();
            this.infowindow.open(map, marker);
            let body = 
              "<div class='infowindow-container'>" +
                "<div class='mb-3'>" + 
                  "<h4 class='me-3'>" + x.title + "</h4>" +
                  (x.hasDate ? "<div>"+x.displaydate+"</div>" : "") +
                  "<div><a href='/"+this.urlname+"/detail/"+x.contentid+"'>Vew Detail</a></div>"
                "</div>" + 
              "</div>";
            this.infowindow.setContent(body);
            //this.router.navigateByUrl('/'+this.urlname+'/detail/'+x.contentid);
          });
          bounds.extend(latlng);
        });
        map.fitBounds(bounds);
      }
    });
  }
}
