import { Component } from '@angular/core';
import { HeaderComponent } from "../../components/header/header.component";
import { SidebarComponent } from "../../components/sidebar/sidebar.component";
import { EditwrapperComponent } from "../../components/editwrapper/editwrapper.component";
import { MapComponent } from "../../components/map/map.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-sitemap',
    standalone: true,
    templateUrl: './sitemap.component.html',
    styleUrl: './sitemap.component.css',
    imports: [HeaderComponent, SidebarComponent, EditwrapperComponent, MapComponent, FooterComponent, CommonModule]
})
export class SitemapComponent {
  site: any = {}
  urlname: any = '';
  pageState: any = { showSidebar: false, editContent: false }

  constructor(
    private http: HttpClient, 
    private deviceService: DeviceDetectorService, 
    public authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.urlname = params.get('urlname');
    });
    if (this.urlname) {
      this.getSite(this.urlname).subscribe((data: any) => {
        this.site = data;
      });
    }
  }

  getSite(urlname: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/site/byname`, {
      params: {
        urlname: urlname
      }
    });
  }
  addContentEvent() {
    this.pageState.editContent = true;
  }
  closeContentEvent() {
    this.pageState.editContent = false;
  }
  cancelContentEvent() {
    this.pageState.editContent = false;
  }
  showSidebarEvent() {
    this.pageState.showSidebar = true;
  }
  closeSidebarEvent() {
    this.pageState.showSidebar = false;
  }
  signOutEvent() {
    this.authService.logout(this.urlname);
  }
  get loggedIn() {
    return this.authService.userValue && this.authService.userValue.expiresIn && this.authService.userValue.expiresIn > 0;
  }
}
