<div class="d-flex">
    <div class="align-self-center w-100">
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <input class="form-control" name="title" [(ngModel)]="websiteurl" name="title">
            <label class="color-blue-dark font-13">Website URL</label>
        </div>
    </div>
    <div class="align-self-center ms-auto">
        <a (click)="enterWebsiteURL()" class="btn btn-full bg-green-dark btn-m text-uppercase font-800 rounded-sm mb-0 ms-3">Apply</a>
    </div>
</div>


<input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">
<button type="button" class="btn btn-success" (click)="file.click()">Upload File</button>

<div style="height:calc(100vh - 400px)">
<div *ngFor="let image of images">
    <img (click)="uploadImageFromURL(image)" src='{{image.url}}' style="width:100%"/>
</div>
</div>