import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private router: Router, private http: HttpClient) { }

  getWebsiteImages(websiteurl: any) {
    let res = this.http.get<any[]>(`${environment.apiUrl}/admin/urlimages`, {
      params: {
        websiteurl: websiteurl
      }
    });
    return res;
  }
  getAllSites() {
    let res = this.http.get<any[]>(`${environment.apiUrl}/admin/allsites`, { });
    return res;
  }
  createSite(site: any) {
    return this.http.post<any>(`${environment.apiUrl}/admin/createsite`, null, {
      params: {
        title: site.title,
        urlname: site.urlname
      }
    })
    .pipe(map(resp => {
      return true;
    }));
  }
}
