import { Component, Input } from '@angular/core';
import { ViewsiteService } from '../../services/viewsite.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-imagegallery',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './imagegallery.component.html',
  styleUrl: './imagegallery.component.css'
})
export class ImagegalleryComponent {
  @Input() urlname: any;
  @Input() content: any;
  images: any = []

  constructor(public viewSiteService: ViewsiteService) {}

  ngOnInit() {
    this.populateImages();
  }
  populateImages() {
    this.viewSiteService.getImages(this.urlname, this.content.contentid).subscribe((data: any) => {
      this.images = data;
    });
  }
}
