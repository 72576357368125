import { Routes } from '@angular/router';
import { SiteComponent } from './pages/site/site.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { SitecalendarComponent } from './pages/sitecalendar/sitecalendar.component';
import { ContentdetailComponent } from './pages/contentdetail/contentdetail.component';

export const routes: Routes = [
    { path:  '', component:  HomeComponent},
    { path:  'login', component:  LoginComponent},
    { path:  'register', component:  RegisterComponent},
    { path:  ':urlname/detail/:contentid', component:  ContentdetailComponent},
    { path:  ':urlname/map', component:  SitemapComponent},
    { path:  ':urlname/calendar', component:  SitecalendarComponent},
    { path:  ':urlname', component:  SiteComponent},
];
