<body class="theme-light">
<div id="page" class="device-is-android">
        
    <div class="page-content pb-0">
        
        <div class="card" data-card-height="cover" style="height: 943px;">
            
            <!-- shows in light mode-->
            <div class="show-on-theme-light card-center text-center">
                <img class="preload-img entered loading" src="/assets/images/powerbutton.png" width="90" data-ll-status="loading">
                <h1 class="color-black font-40 font-800 mt-3">Infopack</h1>
                <p class="color-black opacity-50">Share information quickly and easily</p>
                
                <p class="boxed-text-xl font-14 font-400 line-height-l color-black">
                    Infopack lets you create a website to share maps, calendars and other information.
                </p>
                <a [routerLink]="'/login'" data-back-button="" class="btn btn-m font-900 text-uppercase rounded-l btn-center-l bg-highlight">Edit Your Infopack</a>
            </div>
                        
            <div class="card-overlay bg-theme opacity-85"></div>
            <div class="card-overlay-infinite preload-img entered loaded" data-src="/assets/images/_bg-infinite.jpg" data-ll-status="loaded" style="background-image: url('/assets/images/_bg-infinite.jpg');"></div>
        </div>
    
    </div>
    s
</div>
</body>