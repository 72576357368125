import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IContent } from '../../models/content';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-editmap',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './editmap.component.html',
  styleUrl: './editmap.component.css'
})
export class EditmapComponent {
  @Input() content: IContent | any;
  @ViewChild('mapWrapper', {static: false}) mapElement: ElementRef | undefined;
  @Output() contentUpdatedEvent: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {
    this.initialize();
  }

  initialize() {
    let latLng = (this.content.gmaplat && this.content.gmaplong && this.content.gmapzoom) 
                    ? new google.maps.LatLng(this.content.gmaplat, this.content.gmaplong)
                    : new google.maps.LatLng(51.498726509216176,-0.3345597972976866);
    let zoom = this.content.gmapzoom
                    ? this.content.gmapzoom
                    : 7;
    const mapOptions: google.maps.MapOptions = {
      center: latLng,
      zoom: zoom,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    };
    let map = new google.maps.Map(this.mapElement?.nativeElement, mapOptions);
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(latLng.lat(), latLng.lng()),
      title: this.content.title,
      map: map,
    });
    google.maps.event.addListener(map, 'drag', () => {
      let newLat = map.getCenter().lat();
      let newLong = map.getCenter().lng();
      this.content.gmaplat = newLat;
      this.content.gmaplong = newLong;
      this.content.gmapzoom = map.getZoom();
      marker.setPosition(new google.maps.LatLng(newLat, newLong));
    });
    google.maps.event.addListener(map, 'zoom_changed', () => { 
      let newZoom = map.getZoom();
      this.content.gmapzoom = newZoom;
    });
  }
  handleCancel() {

  }
  handleBack() {
    this.contentUpdatedEvent.emit(this.content);
  }
  handleDelete() {
    this.content.gmaplat = 0;
    this.content.gmaplong = 0;
    this.content.gmapzoom = 0;
    this.contentUpdatedEvent.emit(this.content);
  }
}
