import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Observable, catchError, of, switchMap, throwError } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem('accessToken');
    
        if (accessToken) {
          request = this.addToken(request, accessToken);
        }
    
        return next.handle(request).pipe(
          catchError((error) => {
            // Check if the error is due to an expired access token
            if (error.status === 401 && accessToken) {
              return this.handleTokenExpired(request, next);
            }
    
            return throwError(error);
          })
        );
      }

      private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Call the refresh token endpoint to get a new access token
        return this.authService.refreshAccessToken().pipe(
          switchMap(() => {
            const newAccessToken: any = localStorage.getItem('accessToken');
            // Retry the original request with the new access token
            return next.handle(this.addToken(request, newAccessToken));
          }),
          catchError((error) => {
            // Handle refresh token error (e.g., redirect to login page)
            console.error('Error handling expired access token:', error);
            return throwError(error);
          })
        );
      }
      
    /*private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            console.log('here');
            
            // Get new token
            
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authService.userValue;
        const isLoggedIn = user?.accessToken;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            });
        }

        return next
            .handle(request)
            .pipe(catchError((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this.authService.refreshToken();
                }
                return next.handle(this.addToken(request, token['result'].accessToken));
            }));
    }
    private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Call the refresh token endpoint to get a new access token
        return this.authService.refreshAccessToken().pipe(
          switchMap(() => {
            const newAccessToken = localStorage.getItem('accessToken');
            // Retry the original request with the new access token
            return next.handle(this.addToken(request, newAccessToken));
          }),
          catchError((error) => {
            // Handle refresh token error (e.g., redirect to login page)
            console.error('Error handling expired access token:', error);
            return throwError(error);
          })
        );
    }
    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
            Authorization: `Bearer ${token}`,
            },
        });
    }*/
}