import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { HeaderComponent } from "../../components/header/header.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { EditwrapperComponent } from '../../components/editwrapper/editwrapper.component';
import { MapComponent } from "../../components/map/map.component";
import { ImagegalleryComponent } from "../../components/imagegallery/imagegallery.component";
import { ViewsiteService } from '../../services/viewsite.service';

@Component({
    selector: 'app-site',
    standalone: true,
    providers: [],
    templateUrl: './site.component.html',
    styleUrl: './site.component.css',
    imports: [HeaderComponent, FooterComponent, SidebarComponent, EditwrapperComponent, CommonModule, MapComponent, RouterModule, ImagegalleryComponent]
})
export class SiteComponent {
  site: any = {}
  urlname: any = '';
  pageState: any = { showSidebar: false, editContent: false, editHomepage: false }
  events: any = [];

  constructor(
    private http: HttpClient, 
    private deviceService: DeviceDetectorService, 
    public authService: AuthService,
    private route: ActivatedRoute,
    private viewSiteService: ViewsiteService) { }

  ngOnInit() {
    this.initSite();
  }

  async initSite() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.urlname = params.get('urlname');
    });
    if (this.urlname) {
      await this.populateSite(this.urlname);
      this.populateEvents();
    }
  }

  populateSite(urlname: any) {
    this.viewSiteService.getSite(urlname).subscribe((data: any) => {
      this.site = data;
    });
  }
  populateEvents() {
    this.viewSiteService.getEvents(this.urlname, true, 3).subscribe((data: any) => {
      this.events = data;
    });
  }
  addContentEvent() {
    this.pageState.editContent = true;
  }
  closeEditEvent() {
    this.pageState.editContent = false;
    this.pageState.editHomepage = false;
    this.initSite();
  }
  cancelContentEvent() {
    this.pageState.editContent = false;
    this.pageState.editHomepage = false;
  }
  showSidebarEvent() {
    this.pageState.showSidebar = true;
  }
  closeSidebarEvent() {
    this.pageState.showSidebar = false;
  }
  get loggedIn() {
    return this.authService.loggedIn();
  }
  handleClickEditHomepage() {
    this.pageState.editHomepage = true;
  }
  get mainDate() {
    if (!this.site.homepagecontent || !this.site.homepagecontent.whendate) return null;
    let dt = new Date(this.site.homepagecontent.whendate);
    return {
      shortmonth: dt.toLocaleString('default', { month: 'short' }).toString().toUpperCase(),
      day: dt.getDate(),
      showdate: this.site.homepagecontent.whendate.toString() != '0001-01-01T00:00:00'
    }
  }
  get mainImageBgStyle() {
    return this.site.mainimageurl ? 'background-image:url('+ this.site.mainimageurl +')' : '';
  }
}