<div id="page">

    <app-header [title]="site.title" (addContentEvent)="addContentEvent()" (showSidebarEvent)="showSidebarEvent()" [loggedIn]="loggedIn"></app-header>
    <app-sidebar *ngIf="pageState.showSidebar" [loggedIn]="loggedIn" (closeSidebarEvent)="closeSidebarEvent()" (signOutEvent)="signOutEvent()"></app-sidebar>
    <div style="padding-top:50px">
        <app-editwrapper *ngIf="pageState.editContent" [urlname]="urlname" (closeEditEvent)="closeContentEvent()" (cancelContentEvent)="cancelContentEvent()"></app-editwrapper>        
    </div>
    <app-map [urlname]="urlname"></app-map>
    <app-footer [urlname]="urlname"></app-footer>
    
    </div>