import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  @Input() urlname: any;
  
  get link() {
    return {
      map: '/' + this.urlname + '/map',
      home: '/' + this.urlname,
      calendar: '/' + this.urlname + '/calendar'
    }
  }
}
