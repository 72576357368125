import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { AuthService } from '../../services/auth.service';
import { NewinfopackComponent } from '../newinfopack/newinfopack.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [ CommonModule, RouterLink, RouterLinkActive, RouterOutlet, NewinfopackComponent ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  @Input() loggedIn: any = false;
  @Input() urlname: any;
  @Output() closeSidebarEvent = new EventEmitter();
  sites: any = []
  shownew: any = false;

  constructor(public adminService: AdminService, public authService: AuthService) {}

  ngOnInit() {
    this.adminService.getAllSites().subscribe((data: any) => {
      this.sites = data;
    });
  }

  handleSignOut() {
    this.authService.logout(this.urlname);
    this.closeSidebarEvent.emit();
  }
  handleCloseSidebar() {
    this.closeSidebarEvent.emit();
  }
  handleNewPack() {
    this.shownew = true;
  }
  handleClose() {
    this.shownew = false;
  }
}
