import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditsiteService } from '../../services/editsite.service';
import { ViewsiteService } from '../../services/viewsite.service';
import { AdminService } from '../../services/admin.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-uploadimage',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './uploadimage.component.html',
  styleUrl: './uploadimage.component.css'
})
export class UploadimageComponent {
  constructor(public viewSiteService: ViewsiteService, public adminService: AdminService, private http: HttpClient, public editSiteService: EditsiteService) { }
  @Input() urlname: any;
  @Input() content: any;
  websiteurl: any;
  images: any = [];
  
  @Output() completeEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit() { }

  uploadFile = (files: any) => {
    if (files.length === 0) {
      return;
    } 
    (this.editSiteService.uploadImage(this.urlname, this.content.contentid, files)).subscribe(response => {
      this.completeEvent.emit();
    });
  }
  enterWebsiteURL() {
    this.populateImages();
  }
  populateImages() {
    this.adminService.getWebsiteImages(this.websiteurl).subscribe((data: any) => {
      this.images = data;
    });
  }
  uploadImageFromURL(image: any) {
    this.editSiteService.uploadImageFromURL(this.urlname, this.content.contentid, image.url).subscribe((data: any) => {
      
    });
  }
}
