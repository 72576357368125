<p style="margin-bottom:0px;">Add some text</p>
<div class="demo-inline-calendar-card">
    
    <div class="content">
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <input class="form-control" name="title" [(ngModel)]="content.title" name="title">
            <label class="color-blue-dark font-13">Title</label>
        </div>
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <input class="form-control" name="subtitle" [(ngModel)]="content.subtitle" name="subtitle">
            <label class="color-blue-dark font-13">Subtitle</label>
        </div>
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <textarea class="form-control" name="description" [(ngModel)]="content.description" name="description" style="height:150px"></textarea>
            <label class="color-blue-dark font-13">Description</label>
        </div>
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <input class="form-control" name="url" [(ngModel)]="content.url" name="url">
            <label class="color-blue-dark font-13">Website link</label>
        </div>
    </div>

    <div class="mt-4">
        <a (click)="handleBack()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i> Done</a>
        <a (click)="handleCancel()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
    </div>
</div>