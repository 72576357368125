import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditmapComponent } from "../editmap/editmap.component";
import { CommonModule } from '@angular/common';
import { IContent } from '../../models/content';
import { EditdatetimeComponent } from "../editdatetime/editdatetime.component";
import { EditsiteService } from '../../services/editsite.service';
import { EdittextComponent } from "../edittext/edittext.component";
import { EditimagesComponent } from "../editimages/editimages.component";
import { EditiconComponent } from '../editicon/editicon.component';

@Component({
    selector: 'app-editwrapper',
    standalone: true,
    templateUrl: './editwrapper.component.html',
    styleUrl: './editwrapper.component.css',
    imports: [EditmapComponent, CommonModule, EditdatetimeComponent, EdittextComponent, EditimagesComponent, EditiconComponent]
})
export class EditwrapperComponent {
  @Input() urlname: any;
  @Input() content = {} as IContent;
  @Output() cancelContentEvent = new EventEmitter();
  @Output() closeEditEvent = new EventEmitter();
  pageState: any = {}
  deleting: any = false;

  constructor(public editSiteService: EditsiteService) { }

  ngOnInit() {
    this.initType();
  }

  cancelEditContent() {
    this.cancelContentEvent.emit();
  }
  handleClickType(type: any) {
    this.deleting = false;
    if (type=='place') this.pageState.showPlace = true;
    if (type=='datetime') this.pageState.showDateTime = true;
    if (type=='text') this.pageState.showText = true;
    if (type=='images') this.pageState.showImages = true;
    if (type=='icon') this.pageState.showIcon = true;
  }
  initType() {
    this.pageState = { showPlace: false, showDateTime: false, showText: false, showImages: false };
  }
  updateContent(updatedcontent: any) {
    this.content = updatedcontent;
    this.initType();
  }
  saveContent() {
    this.editSiteService.updateContent(this.urlname, this.content).subscribe((data: any) => {
      this.closeEditEvent.emit();
    });
  }
  clickDelete() {
    this.deleting = true;
  }
  clickDeleteConfirm() {
    this.editSiteService.deleteContent(this.urlname, this.content.contentid).subscribe((data: any) => {
      this.cancelContentEvent.emit();
    });
    this.deleting = false;
  }
  get selectedStyle() {
    return {
      place: this.content.gmaplat && this.content.gmaplong ? 'selected' : '',
      datetime: this.content.whendate && this.content.whendate.toString() != '0001-01-01' ? 'selected' : '',
      text: this.content.description || this.content.url ? 'selected' : '',
      icon: this.content.markerfaicon ? 'selected' : '',
      images: ''
    }
  }
}
