<div class="menu-hider menu-active"></div>
<div id="modal-wizard-step-1" class="menu menu-box-modal rounded-m menu-active" style="display: block; width: 90%; max-height: unset">
    <div class="content">

        <h3 style="margin-bottom:0px;text-align:center">Create an Infopack</h3>
        <div class="demo-inline-calendar-card">
            
            <div class="content">
                <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
                    <input class="form-control" name="title" [(ngModel)]="infopack.title" name="title">
                    <label class="color-blue-dark font-13">Title</label>
                </div>
                <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
                    <input class="form-control" name="title" [(ngModel)]="infopack.urlname" name="urlname">
                    <label class="color-blue-dark font-13">URL Name</label>
                </div>
            </div>
        
            <div class="mt-4">
                <a (click)="handleSave()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i>Save</a>
                <a (click)="handleCancel()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
            </div>
        </div>

    </div>
</div>