import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-newinfopack',
  standalone: true,
  imports: [FormsModule,  RouterModule],
  templateUrl: './newinfopack.component.html',
  styleUrl: './newinfopack.component.css'
})
export class NewinfopackComponent {
  constructor(private router: Router, public adminService: AdminService) { }
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  infopack: any = {}

  handleSave() {
    this.adminService.createSite(this.infopack).subscribe((result: any) => {
      //this.router.navigate(['/'+this.infopack.urlname]);
      window.location.href = '/'+this.infopack.urlname;
    });
  }
  handleCancel() {
    this.closeEvent.emit();
  }
}
