import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadimageComponent } from "../uploadimage/uploadimage.component";
import { ViewsiteService } from '../../services/viewsite.service';
import { HttpClient } from '@angular/common/http';
import { EditsiteService } from '../../services/editsite.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-editimages',
    standalone: true,
    templateUrl: './editimages.component.html',
    styleUrl: './editimages.component.css',
    imports: [UploadimageComponent, CommonModule]
})
export class EditimagesComponent {
  constructor(public viewSiteService: ViewsiteService, private http: HttpClient, public editSiteService: EditsiteService) { }
  @Input() urlname: any;
  @Input() content: any;
  @Output() contentUpdatedEvent: EventEmitter<any> = new EventEmitter();
  images: any = []

  ngOnInit() {
    this.populateImages();
  }

  /*uploadFile(files: any) {
    if (files.length === 0) {
      return;
    } 
    (this.editSiteService.uploadImage(this.urlname, this.content.contentid, files)).subscribe(response => {
      this.populateImages();
    });
  }*/

  populateImages() {
    this.viewSiteService.getImages(this.urlname, this.content.contentid).subscribe((data: any) => {
      this.images = data;
    });
  }
  async makeImageMain(contentimageid: any) {
    (this.editSiteService.updateMainImage(this.urlname, this.content.contentid, contentimageid)).subscribe(response => {
      this.content.mainimageid = contentimageid;
      this.populateImages();
    });
  }
  async deleteImage(contentimageid: any) {
    (this.editSiteService.deleteImage(this.urlname, this.content.contentid, contentimageid)).subscribe(response => {
      this.populateImages();
    });
  }
  
  imageUploaded() {
    this.populateImages();
  }
  handleCancel() {

  }
  handleBack() {
    this.contentUpdatedEvent.emit(this.content);
  }
  get apiURL() {
    return environment.apiUrl;
  }
}
