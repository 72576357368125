<div class="menu-hider menu-active"></div>
<div id="modal-wizard-step-1" class="menu menu-box-modal rounded-m menu-active" style="display: block; width: 90%; max-height: unset">
    <div class="content">

        <div class="d-flex">
            <div>
                <h1>Add Content</h1>
            </div>
        </div>
        <div *ngIf="!pageState.showPlace && !pageState.showDateTime && !pageState.showText && !pageState.showImages && !pageState.showIcon">
            <p>
                Choose the type of content you want to add, you can select more than one.
            </p>
            <div class="row">
                <a (click)="handleClickType('place')" class="col-6">
                    <div class="card card-style me-0 mb-3 text-center pt-2 {{selectedStyle.place}}">
                        <i class="fa fa-globe color-dark-dark fa-3x mt-4"></i>
                        <h1 class="pt-4 font-20">Place</h1>
                    </div>
                </a>
                <a (click)="handleClickType('datetime')" class="col-6">
                    <div class="card card-style ms-0 mb-3 text-center pt-2 {{selectedStyle.datetime}}">
                        <i class="fa fa-clock color-dark-dark fa-3x mt-4"></i>
                        <h1 class="pt-4 font-20">Date/Time</h1>
                    </div>
                </a>
                <a (click)="handleClickType('text')" class="col-6">
                    <div class="card card-style me-0 mb-3 text-center pt-2 {{selectedStyle.text}}">
                        <i class="fa fa-file-lines color-dark-dark fa-3x mt-4"></i>
                        <h1 class="pt-4 font-20">Text</h1>
                    </div>
                </a>
                <a (click)="handleClickType('images')" class="col-6">
                    <div class="card card-style ms-0 mb-3 text-center pt-2 {{selectedStyle.images}}">
                        <i class="fa fa-image color-dark-dark fa-3x mt-4"></i>
                        <h1 class="pt-4 font-20">Images</h1>
                    </div>
                </a>
                <a (click)="handleClickType('icon')" class="col-6">
                    <div class="card card-style me-0 mb-3 text-center pt-2 {{selectedStyle.icon}}">
                        <i class="fa fa-icons color-dark-dark fa-3x mt-4"></i>
                        <h1 class="pt-4 font-20">Icon</h1>
                    </div>
                </a>
            </div>
            
            <div style="text-align:center">
                <a (click)="saveContent()" data-menu="modal-wizard-step-2" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4">Save</a>
                <a (click)="cancelEditContent()" data-menu="modal-wizard-step-2" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
                <a *ngIf="!deleting" (click)="clickDelete()" data-menu="modal-wizard-step-2" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Delete</a>
                <a *ngIf="deleting" (click)="clickDeleteConfirm()" data-menu="modal-wizard-step-2" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Confirm</a>
            </div>
        </div>
        <app-editmap [content]="content" *ngIf="pageState.showPlace" (contentUpdatedEvent)="updateContent($event)"></app-editmap>
        <app-editdatetime [content]="content" *ngIf="pageState.showDateTime" (contentUpdatedEvent)="updateContent($event)"></app-editdatetime>
        <app-edittext [content]="content" *ngIf="pageState.showText" (contentUpdatedEvent)="updateContent($event)"></app-edittext>
        <app-editimages [content]="content" [urlname]="urlname" *ngIf="pageState.showImages" (contentUpdatedEvent)="updateContent($event)"></app-editimages>
        <app-editicon [content]="content" *ngIf="pageState.showIcon" (contentUpdatedEvent)="updateContent($event)"></app-editicon>
    </div>
</div>