import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IContent } from '../../models/content';
import { ViewsiteService } from '../../services/viewsite.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-editicon',
  standalone: true,
  imports: [ FormsModule, CommonModule ],
  templateUrl: './editicon.component.html',
  styleUrl: './editicon.component.css'
})
export class EditiconComponent {
  constructor(public viewSiteService: ViewsiteService) { }
  @Input() content: IContent | any;
  @Output() contentUpdatedEvent: EventEmitter<any> = new EventEmitter();
  filtervalue: any = "";
  icons: any = [];
  filteredicons: any = [];

  handleCancel() {

  }
  handleBack() {
    this.contentUpdatedEvent.emit(this.content);
  }

  ngOnInit() {
    this.populateIcons();
  }

  populateIcons() {
    this.viewSiteService.getIcons().subscribe((data: any) => {
      data.forEach((x: any) => {
        this.icons.push(x);
        if (this.content.markerfaicon == x.name) {
          x.class = 'selected';
        }
      })
      this.filteredicons = data;
    });
  }
  selectIcon(iconname: any) {
    this.content.markerfaicon = iconname;
    this.populateIcons();
  }
  filterIcons() {
    this.filteredicons = this.icons.filter((x:any) => x.name.includes(this.filtervalue));
  }
}
