import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { IAuthInfo } from '../models/userModels';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<IAuthInfo | null>;
  public user: Observable<IAuthInfo | null>;

  constructor(
      private router: Router,
      private http: HttpClient
  ) {
      this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
      this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }
  public get storedRefreshToken() {
      return this.userSubject.value?.refreshToken;
  }

  login(username: string, password: string): Observable<any> {
    const body = { email : username, password: password };
    return this.http.post<any>(`${environment.apiUrl}/login`, body)
      .pipe(map(resp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('accessToken', resp.accessToken);
          localStorage.setItem('refreshToken', resp.refreshToken);
          //localStorage.setItem('user', JSON.stringify(user));
          //this.userSubject.next(resp);
          return resp;
      }));
  }

  refreshAccessToken(): Observable<any> {
    // Call the refresh token endpoint to get a new access token
    const refreshToken = localStorage.getItem('refreshToken');
    return this.http.post<any>(`${environment.apiUrl}/refresh`, { refreshToken }).pipe(
      tap((response) => {
        // Update the access token in the local storage
        localStorage.setItem('accessToken', response.accessToken);
      }),
      catchError((error) => {
        // Handle refresh token error (e.g., redirect to login page)
        console.error('Error refreshing access token:', error);
        return throwError(error);
      })
    );
  }
  logout(urlname: any) {
      // remove user from local storage to log user out
      /*localStorage.removeItem('user');
      this.userSubject.next(null);
      this.router.navigate(['/'+urlname]);*/
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
  }
  loggedIn() {
    return localStorage.getItem('accessToken') != null;
  }

  /*refreshToken() {
    const body = { refreshToken : this.storedRefreshToken };
    return this.http.post<any>(`${environment.apiUrl}/refresh`, body)
      .pipe(tap((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
      }),
      catchError((error) => {
        this.logout('');
        return of(false);
      }));
  }*/

}
