import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditwrapperComponent } from '../editwrapper/editwrapper.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ CommonModule, EditwrapperComponent, SidebarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  @Input() title = '';
  @Input() loggedIn: any = false;
  @Input() urlname: any = '';

  pageState: any = { showSidebar: false, editContent: false }

  handleAddContent() {
    this.handleCloseAll();
    this.pageState.editContent = true;
  }
  handleShowSidebar() {
    this.handleCloseAll();
    this.pageState.showSidebar = true;
  }
  handleCloseAll() {
    this.pageState = { showSidebar: false, editContent: false };
  }
}
