<div class="page-content pb-0">
    <div data-card-height="cover" class="card" style="height: 759px;">
        <div class="card-top notch-clear">
            <a href="#" data-back-button="" class="me-auto icon icon-m"><i class="font-14 fa fa-arrow-left color-white"></i></a>
        </div>
        <div class="card-center bg-white mx-3 py-4 rounded-m">
            <div class="p-4 py-3">
                <h1 class="text-center font-800 font-40 mb-1">Sign Up</h1>
                <p class="color-highlight text-center font-12">Create an Account. It's free!</p>
                <div class="input-style no-borders has-icon validate-field">
                    <i class="fa fa-user"></i>
                    <input type="name" class="form-control validate-name" id="form1a" placeholder="Name">
                    <label for="form1a" class="color-blue-dark font-10 mt-1">Name</label>
                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                    <i class="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>
                <div class="input-style no-borders has-icon validate-field mt-2">
                    <i class="fa fa-at"></i>
                    <input type="email" class="form-control validate-name" id="form1aa" placeholder="Email">
                    <label for="form1aa" class="color-blue-dark font-10 mt-1">Email</label>
                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                    <i class="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>
                <div class="input-style no-borders has-icon validate-field mt-2">
                    <i class="fa fa-lock"></i>
                    <input type="password" class="form-control validate-text" id="form3a" placeholder="Choose a Password">
                    <label for="form3a" class="color-blue-dark font-10 mt-1">Choose a Password</label>
                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                    <i class="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>
                <div class="input-style no-borders has-icon validate-field mt-2">
                    <i class="fa fa-lock"></i>
                    <input type="password" class="form-control validate-text" id="form3a1" placeholder="Confirm your Password">
                    <label for="form3a1" class="color-blue-dark font-10 mt-1">Confirm your Password</label>
                    <i class="fa fa-times disabled invalid color-red-dark"></i>
                    <i class="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>
                <div class="text-center my-4 py-1">
                    <a href="page-signin-4.html" class="font-12">Already Registered? Sign in Here</a>
                </div>
                <a href="#" class="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-900 bg-highlight">Create Account</a>
            </div>

        </div>
        <div class="card-overlay-infinite preload-img entered loaded" data-src="images/pictures/_bg-infinite.jpg" data-ll-status="loaded" style="background-image: url(&quot;images/pictures/_bg-infinite.jpg&quot;);"></div>
    </div>
</div>