<div class="main">
    <p class="mb-3">
        Simply drag and zoom to set the map position
    </p>
    <div #mapWrapper id="map"></div>
    <div class="mt-3" style="text-align:center;width:100%">
        <div class="input-style input-style-always-active has-borders has-icon validate-field">
            <input *ngIf="content.title" class="form-control" name="title" [(ngModel)]="content.title" name="title">
            <label class="color-blue-dark font-13">Optional Title</label>
        </div>
        <div class="mt-4">
            <a (click)="handleBack()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i> Done</a>
            <a (click)="handleCancel()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
            <a (click)="handleDelete()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Delete</a>
        </div>
    </div>
</div>