<div id="page" class="device-is-android">

    <app-header *ngIf="site" [title]="site.title" [urlname]="urlname" [loggedIn]="loggedIn"></app-header>
    <app-footer [urlname]="urlname"></app-footer>
    <div style="padding-top:50px">
        <app-editwrapper *ngIf="pageState.editContent" [content]="content" [urlname]="urlname" (closeEditEvent)="closeContentEvent()" (cancelContentEvent)="cancelContentEvent()"></app-editwrapper>        
    </div>

    <div *ngIf="content" style="padding-top:20px">

        <div class="card card-style">
            <img *ngIf="content.mainimage" data-src="{{content.mainimage}}" class="preload-img img-fluid bottom-20 entered loaded" data-ll-status="loaded" src="images/pictures/18w.jpg">
            <div class="content mb-0 pb-4">
                <div>
                    <h1 class="mb-0">{{content?.title}}</h1>
                    <p>{{content.subtitle}}</p>
                </div>
                
                <div class="clearfix"></div>
                <div class="divider mt-2 mb-3"></div>

                <div *ngIf="content.gmaplat>0 && content.gmaplong>0" class="mb-4">
                    <app-map [content]="content"></app-map>
                </div>

                <div *ngIf="content" class="mb-4" style="font-size:1.1rem">
                    <a *ngIf="content.url" href="{{content.url}}"><i class="fa fa-link me-1"></i>Link to website</a>
                    <a *ngIf="content.gmaplat && content.gmaplong" href="https://www.google.com/maps/dir/?api=1&destination={{content.gmaplat}},{{content.gmaplong}}" class="float-end me-2"><i class="fa fa-diamond-turn-right me-1"></i>Directions</a>
                    <!--<p class="font-10"><i class="fa fa-map-marker-alt me-2"></i>Melbourne Victoria, Collins Street</p>-->
                </div>

                <div class="row">
                    <div class="col-5">
                        <strong class="color-theme">Date:</strong>
                        <p class="font-12"><i class="far fa-calendar me-2"></i>{{content.displaydateshort}}</p>
                    </div>
                    <div *ngIf="content.displaytime" class="col-7">
                        <strong class="color-theme">Time:</strong>
                        <p class="font-12"><i class="far fa-clock me-2"></i>{{content.displaytime}}</p>
                    </div>
                </div>

                <h5 *ngIf="content.description">Description</h5>
                <p *ngIf="content.description" style="white-space: pre-wrap;">{{content.description}}</p>
                <div *ngIf="images.length>1">
                    <div class="row text-center row-cols-2 mb-4 mt-4" style="margin:0">
                        <a *ngFor="let image of images" class="col mb-3" data-gallery="gallery-1" href="{{image.urlmd}}" title="Vynil and Typerwritter">
                            <img src="{{image.urltn}}" data-src="{{image.urltn}}" class="preload-img img-fluid rounded-xs entered loaded" alt="img" data-ll-status="loaded">
                            <p class="font-600 pb-1">{{image.caption}}</p>
                        </a>
                    </div>
                </div>
                <a *ngIf="loggedIn" (click)="addContentEvent()" class="btn btn-m btn-full rounded-sm shadow-xl bg-highlight text-uppercase mb-3 font-900">Edit This Information</a>
            </div>
            
        </div>
        
    </div>

</div>