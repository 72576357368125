import { Component } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { EditwrapperComponent } from '../../components/editwrapper/editwrapper.component';
import { MapComponent } from '../../components/map/map.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ViewsiteService } from '../../services/viewsite.service';

@Component({
  selector: 'app-sitecalendar',
  standalone: true,
  imports: [HeaderComponent, SidebarComponent, EditwrapperComponent, MapComponent, FooterComponent, CommonModule, RouterModule],
  templateUrl: './sitecalendar.component.html',
  styleUrl: './sitecalendar.component.css'
})
export class SitecalendarComponent {
  site: any = {};
  eventsFuture: any = [];
  eventsPast: any = [];
  urlname: any = '';
  pageState: any = { showSidebar: false, editContent: false }

  constructor(
    private http: HttpClient, 
    private deviceService: DeviceDetectorService, 
    public authService: AuthService,
    private route: ActivatedRoute,
    public viewSiteService: ViewsiteService) { }

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.urlname = params.get('urlname');
    });
    if (this.urlname) {
      await this.getSite(this.urlname).subscribe((data: any) => {
        this.site = data;
        this.populateEvents();
      });
    }
  }

  populateEvents() {
    this.viewSiteService.getEvents(this.urlname, false, 0).subscribe((data: any) => {
      this.eventsFuture = data.filter((x:any) => !x.isInPast);
      this.eventsPast = data.filter((x:any) => x.isInPast);
    });
  }
  getSite(urlname: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/site/byname`, {
      params: {
        urlname: urlname
      }
    });
  }
  addContentEvent() {
    this.pageState.editContent = true;
  }
  closeContentEvent() {
    this.pageState.editContent = false;
  }
  cancelContentEvent() {
    this.pageState.editContent = false;
  }
  showSidebarEvent() {
    this.pageState.showSidebar = true;
  }
  closeSidebarEvent() {
    this.pageState.showSidebar = false;
  }
  signOutEvent() {
    this.authService.logout(this.urlname);
  }
  get loggedIn() {
    return this.authService.userValue && this.authService.userValue.expiresIn && this.authService.userValue.expiresIn > 0;
  }
}
