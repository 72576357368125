<div id="page">

<app-header *ngIf="urlname" [title]="site.title" [urlname]="urlname" (addContentEvent)="addContentEvent()" (showSidebarEvent)="showSidebarEvent()" [loggedIn]="loggedIn"></app-header>
<app-footer *ngIf="urlname" [urlname]="urlname"></app-footer>

<div *ngIf="pageState.editContent" style="padding-top:50px">
    <app-editwrapper [urlname]="urlname" (closeEditEvent)="closeEditEvent()" (cancelContentEvent)="cancelContentEvent()"></app-editwrapper>        
</div>
<div *ngIf="pageState.editHomepage" style="padding-top:50px">
    <app-editwrapper [content]="site.homepagecontent" [urlname]="urlname" (closeEditEvent)="closeEditEvent()" (cancelContentEvent)="cancelContentEvent()"></app-editwrapper>        
</div>

<div class="page-content pt-8">
        
    <div *ngIf="loggedIn" class="content">
        <a (click)="handleClickEditHomepage()" class="btn btn-full font-900 text-uppercase bg-red-dark btn-m rounded-sm">Edit Your Homepage</a>
    </div>
    
    <div *ngIf="mainImageBgStyle" class="card card-style" data-card-height="300" style="height: 250px;" style="{{mainImageBgStyle}}">
        <div *ngIf="mainDate?.showdate" class="card-top">
            <strong class="float-end text-center">
                <div class="bg-theme rounded-sm color-theme shadow-xl text-center m-3 overflow-hidden" style="background-color:white">
                    <span class="bg-red-dark font-10 d-block mb-2 px-3 line-height-xs py-1">{{mainDate?.shortmonth}}</span>
                    <span class="font-23 font-800 d-block mb-n3 line-height-s">{{mainDate?.day}}</span><br>
                </div>
            </strong>
        </div>
        <div class="card-bottom no-click">
            <div class="content">
                <h1 class="color-white mt-1">{{site.homepagecontent?.title}}</h1>
                <h2 *ngIf="site.homepagecontent?.subtitle" class="color-white opacity-50 font-13 mt-n2 font-400">{{site.homepagecontent?.subtitle}}</h2>
            </div>
        </div>
        <div class="card-overlay bg-gradient"></div>
    </div>
    
    <div *ngIf="events && events.length>0" class="card card-style p-4">
        <h4 class="pb-1">Upcoming events</h4>
        <div *ngFor="let event of events" class="d-flex" [routerLink]="'/'+urlname+'/detail/'+event.contentid">
            <div class="pt-3">
                <i class="fa-regular fa-calendar mt-2 me-3" style="font-size:1.4rem"></i>
            </div>
            <div>
                <span class="color-highlight font-300 d-block pt-2 text-uppercase font-10">{{event?.displaydate}}</span>
                <strong class="color-theme font-16 d-block mt-n2">{{event?.title}}</strong>
                <span *ngIf="event?.subtitle" class="font-11 d-block mb-n1"><i class="fa fa-map-marker pe-2"></i>{{event?.subtitle}}</span>
            </div>
        </div>
        <p class="mt-n1 font-12 pt-2 mb-0">Max 3 shown - click the calendar icon to view all</p>
    </div>
    
    <div *ngIf="site.homepagecontent?.description" class="card card-style p-4">
        <h4 class="pb-2">Description</h4>
        <p>{{site.homepagecontent?.description}}</p>
    </div>

    <app-imagegallery *ngIf="site.homepagecontent && site.hasMultipleImages" [urlname]="urlname" [content]="site.homepagecontent"></app-imagegallery>
</div>

</div>