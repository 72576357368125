import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ViewsiteService } from '../../services/viewsite.service';

@Component({
  selector: 'app-login',
  standalone: true,
  providers: [],
  imports: [ FormsModule ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  newuser: any = { email: '', password: '' }

  constructor(
    private authService: AuthService,
    private viewSiteService: ViewsiteService,
    private router: Router) {}
  
    handleSubmit() {
    this.authService.login(this.newuser.email, this.newuser.password)
      .pipe(first())
      .subscribe({
          next: () => {
              this.viewSiteService.getSiteLoggedInUser().subscribe((data: any) => {
                let site = data;
                this.router.navigate(['/'+site.urlname]);
              });
              
              // get return url from route parameters or default to '/'
              //const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              //this.router.navigate([returnUrl]);
          },
          error: error => {
              //this.error = error;
              //this.loading = false;
          }
      });
  }
}
