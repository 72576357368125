<p style="margin-bottom:0px;">Select a date and optional time for your event</p>
<div>
    <div class="mb-4">
        <mat-calendar [(selected)]="date"></mat-calendar>
    </div>
    <div>
        <input [ngxTimepicker]="picker" [(ngModel)]="time" class="form-control" placeholder="Choose time..." [format]="24">
        <ngx-material-timepicker #picker></ngx-material-timepicker>
    </div>
    <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
        <input *ngIf="content.title" class="form-control" name="title" [(ngModel)]="content.title" name="title">
        <label class="color-blue-dark font-13">Optional Title</label>
    </div>
    <div class="mt-4">
        <a (click)="handleBack()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i> Done</a>
        <a (click)="handleCancel()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
        <a (click)="handleDelete()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Delete</a>
    </div>
</div>