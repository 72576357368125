<div id="page">

    <app-header [title]="site.title" [urlname]="urlname" [loggedIn]="loggedIn"></app-header>
    <app-sidebar *ngIf="pageState.showSidebar" [loggedIn]="loggedIn" (closeSidebarEvent)="closeSidebarEvent()" (signOutEvent)="signOutEvent()"></app-sidebar>
    <div style="padding-top:50px">
        <app-editwrapper *ngIf="pageState.editContent" [urlname]="urlname" (closeEditEvent)="closeContentEvent()" (cancelContentEvent)="cancelContentEvent()"></app-editwrapper>        
    </div>

    <div class="page-content pt-4">
        <div class="calendar bg-theme shadow-xl rounded-m">
            <div class="cal-footer">
                <h6 class="cal-sub-title uppercase bold bg-mail color-white">Schedule</h6>
                <a *ngFor="let event of eventsFuture" [routerLink]="'/'+urlname+'/detail/'+event.contentid" style="text-decoration: none;color: inherit">
                    <div class="cal-schedule" style="min-height:65px;">
                        <em>{{event.displaydateshort}}<br>{{event.displaytime}}</em>
                        <strong *ngIf="event.title" class="d-block mb-n2">{{event.title}}</strong>
                    </div>
                </a>
                <h6 *ngIf="eventsPast.length>0" class="mt-3 ms-3">Past Activities</h6>
                <a *ngFor="let event of eventsPast" [routerLink]="'/'+urlname+'/detail/'+event.contentid" style="text-decoration: none;color: inherit">
                    <div class="cal-schedule" style="min-height:65px">
                        <em>{{event.displaydateshort}}<br>{{event.displaytime}}</em>
                        <strong *ngIf="event.title" style="color:#999" class="d-block mb-n2">{{event.title}}</strong>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <app-footer [urlname]="urlname"></app-footer>
    
</div>