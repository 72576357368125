import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContent } from '../../models/content';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import moment from 'moment';

@Component({
  selector: 'app-editdatetime',
  standalone: true,
  imports: [MatCardModule, MatDatepickerModule, NgxMaterialTimepickerModule, FormsModule, MomentModule],
  templateUrl: './editdatetime.component.html',
  styleUrl: './editdatetime.component.css',
  providers: [provideNativeDateAdapter()],
})
export class EditdatetimeComponent {
  @Input() content: IContent | any;
  @Output() contentUpdatedEvent: EventEmitter<any> = new EventEmitter();
  time: any;
  date: any;

  ngOnInit() {
    if (this.content && this.content.whendate) {
      let tempdate = new Date(this.content.whendate);
      //if (tempdate.getHours()>0 && tempdate.getMinutes()>0) {
      //  this.time = tempdate.getHours()+':'+tempdate.getMinutes();
      //}
      this.date = this.content.whendate;
    }
  }
  handleCancel() {

  }
  handleBack() {
    let tempdate = new Date(this.date);
    if(this.time && !(this.time.split(':')[0] == 0 && this.time.split(':')[1] == 0)) {
      tempdate.setHours(this.time.split(":")[0]);
      tempdate.setMinutes(this.time.split(":")[1]);
    }
    this.content.whendate = tempdate.toLocaleDateString('en-GB');
    this.contentUpdatedEvent.emit(this.content);
  }
  handleDelete() {
    this.content.whendate = new Date("0001-01-01");
    this.contentUpdatedEvent.emit(this.content);
  }
}
