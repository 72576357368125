<p>Upload images</p>
<app-uploadimage [urlname]="urlname" [content]="content" (completeEvent)="imageUploaded()"></app-uploadimage>

<div class="row text-center row-cols-2 mb-4 mt-4">
    <a *ngFor="let image of images" class="col mb-4" data-gallery="gallery-1" title="Vynil and Typerwritter">
        <img src="{{image.urltn}}" data-src="{{image.urltn}}" class="preload-img img-fluid rounded-xs entered loaded" alt="img" data-ll-status="loaded">
        <div>
            <a (click)="deleteImage(image.imageid)" class="btn btn-m btn-full mb-3 rounded-0 text-uppercase font-900 shadow-s bg-red-light float-left" style="width:50%">Delete</a>
            <a *ngIf="image.imageid!=content.mainimageid" (click)="makeImageMain(image.imageid)" class="btn btn-m btn-full mb-3 rounded-0 text-uppercase font-900 border-blue-dark color-blue-dark bg-theme float-right" style="width:50%">Main</a>
            <a *ngIf="image.imageid==content.mainimageid" (click)="makeImageMain(image.imageid)" class="btn btn-m btn-full mb-3 rounded-0 text-uppercase font-900 bg-blue-dark float-right" style="width:50%">Main</a>
        </div>
        <!--<div *ngIf="false" style="margin-top:13px;">
            <div class="input-style input-style-always-active has-borders no-icon validate-field">
                <input class="form-control validate-text" id="f3a" value="1234&amp;5678" placeholder="">
                <label for="f3a" class="color-blue-dark font-12">Caption</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
            </div>
        </div>-->
    </a>
</div>

<div class="mt-4">
    <a (click)="handleBack()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i> Done</a>
</div>