<div *ngIf="!shownew" id="menu-sidebar-left-1" class="menu menu-box-left menu-box-detached menu-sidebar menu-active" data-menu-width="310" style="display: block; width: 310px;">
    <div class="sidebar-content">
        <div class="mb-4">
            <a *ngIf="!loggedIn" [routerLink]="'/login'" class="icon icon-xxs rounded-sm float-start close-menu mt-3 ms-3">
                <i class="fa font-12 fa-sign-in gradient-green rounded-sm color-white me-2"></i>
                <span style="color:#6c6c6c;font-weight:500;font-size:13px;">Login</span>
            </a>
            <a *ngIf="loggedIn" (click)="handleSignOut()" class="icon icon-xxs rounded-sm float-start close-menu mt-3 ms-3">
                <i class="fa font-12 fa-sign-out gradient-green rounded-sm color-white me-2"></i>
                <span style="color:#6c6c6c;font-weight:500;font-size:13px;">Logout</span>
            </a>
            <a (click)="handleCloseSidebar()" class="icon icon-xxs gradient-red rounded-sm float-end close-menu mt-3 me-3"><i class="fa fa-times color-white"></i></a>
        </div>

        <div *ngIf="loggedIn" class="ms-3 me-3 mb-2">
            <a (click)="handleNewPack()" class="btn btn-full font-900 text-uppercase bg-red-dark btn-m rounded-sm">Create an Infopack</a>
        </div>

        <div *ngIf="loggedIn" class="card card-style">
            <div class="content my-0">
                <h5 class="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">My Infopacks</h5>
                <div class="list-group list-custom-small list-icon-0">
                    <a *ngFor="let site of sites" href="/{{site.urlname}}" routerLinkActive="active">
                        <span>{{site.title}}</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-newinfopack *ngIf="shownew" (closeEvent)="handleClose()"></app-newinfopack>