<p style="margin-bottom:0px;">Add some text</p>
<div class="demo-inline-calendar-card">
    
    <div class="content">
        <div class="input-style input-style-always-active has-borders has-icon validate-field mt-4">
            <input class="form-control" [(ngModel)]="filtervalue" name="title" (keyup)="filterIcons()">
            <label class="color-blue-dark font-13">Title</label>
        </div>
        <div class="row" style="height: 400px;overflow:auto;">
            <div *ngFor="let icon of filteredicons" class="col-3 mt-4" style="text-align:center">
                <div>
                    <i class="fa fa-{{icon.name.toLowerCase()}} icon {{icon.class}}" (click)="selectIcon(icon.name)"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4">
        <a (click)="handleBack()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4"><i class="fa fa-arrow-left" style="margin-right:5px;"></i> Done</a>
        <a (click)="handleCancel()" class="btn btn-m rounded-m bg-blue-dark font-700 text-uppercase mx-2 mb-4 opacity-50">Cancel</a>
    </div>
</div>