import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewsiteService {

  constructor(private http: HttpClient) { }

  getMapMarkers(urlname: any) {
    let markers = this.http.get<any[]>(`${environment.apiUrl}/site/mapmarkers`, {
      params: {
        urlname: urlname
      }
    });
    return markers;
  }
  getEvents(urlname: any, futureonly: boolean, amount: any) {
    let events = this.http.get<any[]>(`${environment.apiUrl}/site/events`, {
      params: {
        urlname: urlname,
        futureonly: futureonly,
        amount: amount
      }
    });
    return events;
  }
  getSite(urlname: any) {
    let site = this.http.get<any>(`${environment.apiUrl}/site/byname`, {
      params: {
        urlname: urlname
      }
    });
    return site;
  }
  getSiteLoggedInUser() {
    let site = this.http.get<any>(`${environment.apiUrl}/site/byloggedinuser`, { });
    return site;
  }
  getContent(urlname: any, contentid: any) {
    let event = this.http.get<any>(`${environment.apiUrl}/site/content`, {
      params: {
        urlname: urlname,
        contentid: contentid
      }
    });
    return event;
  }
  getImages(urlname: any, contentid: any) {
    let images = this.http.get<any[]>(`${environment.apiUrl}/site/images`, {
      params: {
        urlname: urlname,
        contentid: contentid
      }
    });
    return images;
  }
  getIcons() {
    let icons = this.http.get<any[]>(`${environment.apiUrl}/fontawesome/all`, { });
    return icons;
  }
}
