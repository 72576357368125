import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContent } from '../../models/content';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-edittext',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './edittext.component.html',
  styleUrl: './edittext.component.css'
})
export class EdittextComponent {
  @Input() content: IContent | any;
  @Output() contentUpdatedEvent: EventEmitter<any> = new EventEmitter();
  
  handleCancel() {

  }
  handleBack() {
    this.contentUpdatedEvent.emit(this.content);
  }
}
