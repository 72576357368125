import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ViewsiteService } from '../../services/viewsite.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HeaderComponent } from "../../components/header/header.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { AuthService } from '../../services/auth.service';
import { SidebarComponent } from "../../components/sidebar/sidebar.component";
import { EditwrapperComponent } from "../../components/editwrapper/editwrapper.component";
import { CommonModule } from '@angular/common';
import { MapComponent } from "../../components/map/map.component";

@Component({
    selector: 'app-contentdetail',
    standalone: true,
    templateUrl: './contentdetail.component.html',
    styleUrl: './contentdetail.component.css',
    imports: [HeaderComponent, FooterComponent, SidebarComponent, EditwrapperComponent, CommonModule, MapComponent]
})
export class ContentdetailComponent {
  site: any;
  content: any;
  urlname: any;
  urlcontentid: any;
  pageState: any = { showSidebar: false, editContent: false }
  images: any = []

  constructor(
    public viewSiteService: ViewsiteService,
    private route: ActivatedRoute,
    public authService: AuthService,) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.urlname = params.get('urlname');
      this.urlcontentid = params.get('contentid');
      this.populateSite(this.urlname);
      this.populateContent(this.urlname, this.urlcontentid);
      this.populateImages(this.urlname, this.urlcontentid);
    });
  }

  populateSite(urlname: any) {
    this.viewSiteService.getSite(urlname).subscribe((data: any) => {
      this.site = data;
    });
  }
  populateContent(urlname: any, contentid: any) {
    this.viewSiteService.getContent(urlname, contentid).subscribe((data: any) => {
      this.content = data;
    });
  }
  populateImages(urlname: any, contentid: any) {
    this.viewSiteService.getImages(urlname, contentid).subscribe((data: any) => {
      this.images = data;
    });
  }

  addContentEvent() {
    this.pageState.editContent = true;
  }
  closeContentEvent() {
    this.pageState.editContent = false;
  }
  cancelContentEvent() {
    this.pageState.editContent = false;
  }
  signOutEvent() {
    this.authService.logout(this.urlname);
  }
  get loggedIn() {
    return this.authService.userValue && this.authService.userValue.expiresIn && this.authService.userValue.expiresIn > 0;
  }
}
