import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContent } from '../models/content';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditsiteService {

  constructor(private http: HttpClient) { }

  updateContent(urlname: any, content: IContent): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/site/content`, null, {
      params: {
        urlname: urlname,
        content: JSON.stringify(content)
      }
    });
  }
  
  deleteContent(urlname: any, contentid: any) {
    return this.http.post<any>(`${environment.apiUrl}/site/contentdelete`,null, {
      params: {
        urlname: urlname,
        contentid: contentid
      }
    });
  }

  uploadImage(urlname: any, contentid: any, files: any[]) {
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(`${environment.apiUrl}/site/image`, formData, {
      params: {
        urlname: urlname,
        contentid: contentid
      }
    })
  }

  uploadImageFromURL(urlname: any, contentid: any, imageurl: any) {
    return this.http.post(`${environment.apiUrl}/site/imagefromurl`, null, {
      params: {
        urlname: urlname,
        contentid: contentid,
        imageurl: imageurl
      }
    })
  }
  
  updateMainImage(urlname: any, contentid: any, contentimageid: any) {
    return this.http.post(`${environment.apiUrl}/site/content/setmainimage`, null, {
      params: {
        urlname: urlname,
        contentid: contentid,
        contentimageid: contentimageid
      }
    })
  }
  
  deleteImage(urlname: any, contentid: any, contentimageid: any) {
    return this.http.post(`${environment.apiUrl}/site/image/delete`, null, {
      params: {
        urlname: urlname,
        contentid: contentid,
        contentimageid: contentimageid
      }
    })
  }
}
