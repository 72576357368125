<div class="page-content header-clear-medium">
    <div class="card card-style">
        <div class="content mt-4 mb-1">
            <h1 class="text-center font-800 font-40 pt-2 mb-1">Sign In</h1>
            <p class="color-highlight text-center font-12 mb-3">Let's get you logged in</p>

            <div class="input-style no-borders has-icon validate-field mb-4">
                <i class="fa fa-user"></i>
                <input type="name" class="form-control validate-name" id="form1a" placeholder="Email" [(ngModel)]="newuser.email">
                <label for="form1a" class="color-blue-dark font-10 mt-1">Email</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
            </div>

            <div class="input-style no-borders has-icon validate-field mb-4">
                <i class="fa fa-lock"></i>
                <input type="password" class="form-control validate-password" id="form3a" placeholder="Password" [(ngModel)]="newuser.password">
                <label for="form3a" class="color-blue-dark font-10 mt-1">Password</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
            </div>

            <a (click)="handleSubmit()" class="btn btn-m rounded-sm mt-4 mb-4 btn-full bg-green-dark text-uppercase font-900">Login</a>

            <div class="d-flex">
                <div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-start"><a href="page-signup-1.html" class="color-theme">Create Account</a></div>
                <div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-end"><a href="page-forgot-1.html" class="color-theme">Forgot Credentials</a></div>
            </div>
        </div>

    </div>

</div>